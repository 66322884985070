.input__thread__container{
	margin: 0 auto;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.input__thread__name {
	width: 40vw;
	padding: 10px 0;
	text-align: center;
	font-size: 15px;
}

.input__thread__btn{
	padding: 3px;
	margin: 10px;
	border: 0.5px solid #000;
	background-color: #fff;
}

.input__thread__btn:hover{
	cursor: pointer;
}