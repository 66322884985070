.forum__container {
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.forum__header {
	text-align: center;
	font-size: 40px;
}