.thread__list__item {
	box-sizing: border-box;
	list-style-type: none;
	background-color: #fff;
	border: 1px solid #000;
	margin: 5px 0;
	width: 100%;
	align-items: center;
	word-wrap: break-word;
}
.thread__item__text {
	font-weight: 400;
	padding: 5px 7px;
}