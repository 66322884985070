.thread__list__container {
	
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 100%;
}
.thread__list__header {
	font-size: 30px;
	text-align: center;
	align-items: center;
}

.thread__list__item__container {
display: flex;
flex-direction: column;
flex-wrap: wrap;
padding: 5px;
max-width: 100%;
}
